import { IonIcon, IonItem, IonLabel } from '@ionic/react'
import { alertCircleOutline } from 'ionicons/icons'
import React from 'react'

type AlertBannerProps = {
  type?: string;
  classTxt?: string;
  text: string;
};

const AlertBanner: React.FC<AlertBannerProps> = ({
                                                   type = 'info',
                                                   classTxt = 'ion-color ion-margin-top ion-margin-bottom alertBanner',
                                                   text = '',
                                                 }) => {
  return (
    <IonItem className={`ion-color ion-margin-top ion-margin-bottom ${classTxt}`} lines="none">
      <IonIcon className="ion-padding-start" color={type} icon={alertCircleOutline} slot="start" aria-hidden={true} />
      <IonLabel className="ion-text-wrap" color={type}>
        {text}
      </IonLabel>
    </IonItem>
  )
}

export default AlertBanner
