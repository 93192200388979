// src/api/NewsAPI.ts

import { API_PATH } from '../constants/defaults'
import { News } from '../types/interfaces'
import { BaseAPI } from './BaseAPI'

/**
 * NewsAPI class
 * @classdesc Class for interacting with the news API
 */
export class NewsAPI extends BaseAPI {
  /**
   * Get the news
   * @returns {Promise<News[]>} - The news
   */
  public async getNews(): Promise<News[]> {
    try {
      return this.api.get(API_PATH.NEWS).then(response => response.data)
    } catch (error) {
      throw error
    }
  }
}
