// src/store/redux/slices/newsSlice.ts

import { createSlice, createAsyncThunk, PayloadAction, AsyncThunk } from '@reduxjs/toolkit'
import { News } from '../../../types/interfaces'
import { AppState } from '../types'
import { NewsAPI } from '../../../api/NewsAPI'
import { WritableDraft } from 'immer'
import { getRegionCode } from '../utils/getRegionCode'
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'

export interface NewsState {
  news: News[];
  isLoading: boolean;
  error: string | null;
}

export const initialState: NewsState = {
  news: [],
  isLoading: false,
  error: null,
}

export const fetchNews: AsyncThunk<News[], void, AsyncThunkConfig> = createAsyncThunk(
  'news/fetchNews',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const newsAPI: NewsAPI = new NewsAPI(regionCode)
    try {
      return await newsAPI.getNews()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNews.pending, (state: WritableDraft<NewsState>) => {
        state.isLoading = true
      })
      .addCase(fetchNews.fulfilled, (state: WritableDraft<NewsState>, action: PayloadAction<News[]>) => {
        state.news = action.payload
        state.isLoading = false
      })
      .addCase(fetchNews.rejected, (state: WritableDraft<NewsState>, action) => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })
  },
})

export default newsSlice.reducer
