// src/store/redux/slices/projectSlice.ts

import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from '../types'
import { ProjectAPI } from '../../../api/ProjectAPI'
import { WritableDraft } from 'immer'
import { getRegionCode } from '../utils/getRegionCode'
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import {
  Project,
  ProjectLeaderProject,
  ProjectLeaderShift,
  ProjectOptions,
  SalesforcePicklistEntry,
  Shift,
  Volunteer,
} from '../../../types/interfaces'
import { getPreference, setPreference } from '../../../utils/preferencesStorage'
import { VolunteerShiftHourStatus } from '../../../types/types'

interface ProjectObject {
  [key: string]: Project
}

interface ProjectShiftsPLObject {
  [key: string]: ProjectLeaderShift[]
}

interface ProjectShiftsObject {
  [key: string]: Shift[]
}

export interface ProjectState {
  projects: ProjectObject
  projectsPL: ProjectLeaderProject[]
  projectShiftsPL: ProjectShiftsPLObject
  projectShifts: ProjectShiftsObject
  projectOptions: ProjectOptions
  filteredProjects: Project[]
  filteredProjectsPL: ProjectLeaderProject[]
  showFilterInfo: boolean
  isLoading: boolean
  error: string | null
}

export const initialState: ProjectState = {
  projects: {},
  projectsPL: [],
  projectShiftsPL: {},
  projectShifts: {},
  projectOptions: {
    days: [],
    tags: [],
    locations: [],
  },
  filteredProjects: [],
  filteredProjectsPL: [],
  showFilterInfo: true,
  isLoading: false,
  error: null,
}

interface ApplyToProjectPayload {
  projectId: string
  message: string
}

interface ProjectLeaderShiftsPayload {
  projectId: string
  shiftsPL: ProjectLeaderShift[]
}

interface ProjectShiftsPayload {
  projectId: string
  shifts: Shift[]
}

export const fetchProjects: AsyncThunk<ProjectObject, void, AsyncThunkConfig> =
  createAsyncThunk(
    'projects/fetchProjects',
    async (_, { getState, rejectWithValue }) => {
      const regionCode: string = getRegionCode(getState() as AppState)
      const projectAPI: ProjectAPI = new ProjectAPI(regionCode)
      try {
        const projects: Project[] = await projectAPI.getProjects()
        // Convert the projects array to an object for easier access and manipulation
        return projects.reduce((acc: ProjectObject, project: Project) => {
          acc[project.ProjectId] = project
          return acc
        }, {})
      } catch (error) {
        return rejectWithValue(error)
      }
    },
  )

export const fetchProject: AsyncThunk<Project, string, AsyncThunkConfig> =
  createAsyncThunk(
    'projects/fetchProject',
    async (projectId: string, { getState, rejectWithValue }) => {
      const regionCode: string = getRegionCode(getState() as AppState)
      const projectAPI: ProjectAPI = new ProjectAPI(regionCode)
      try {
        return await projectAPI.getProject(projectId)
      } catch (error) {
        return rejectWithValue(error)
      }
    },
  )

export const applyToProject: AsyncThunk<
  string,
  ApplyToProjectPayload,
  AsyncThunkConfig
> = createAsyncThunk(
  'projects/applyToProject',
  async (payload: ApplyToProjectPayload, { getState, rejectWithValue }) => {
    const { projectId, message } = payload
    const regionCode: string = getRegionCode(getState() as AppState)
    const projectAPI: ProjectAPI = new ProjectAPI(regionCode)
    try {
      return await projectAPI.applyToProject(
        projectId,
        message,
      )
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchProjectsPL: AsyncThunk<
  ProjectLeaderProject[],
  void,
  AsyncThunkConfig
> = createAsyncThunk(
  'projects/fetchProjectsPL',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const projectAPI: ProjectAPI = new ProjectAPI(regionCode)
    try {
      return await projectAPI.getProjectLeaderProjects()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchShiftsPL: AsyncThunk<
  ProjectLeaderShiftsPayload,
  string,
  AsyncThunkConfig
> = createAsyncThunk(
  'projects/fetchShiftsPL',
  async (projectId: string, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const projectAPI: ProjectAPI = new ProjectAPI(regionCode)
    try {
      const shiftsPL: ProjectLeaderShift[] =
        await projectAPI.getProjectLeaderShifts(projectId)
      return { projectId, shiftsPL }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchProjectOptions: AsyncThunk<
  ProjectOptions,
  void,
  AsyncThunkConfig
> = createAsyncThunk(
  'projects/fetchProjectOptions',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const projectAPI: ProjectAPI = new ProjectAPI(regionCode)
    try {
      return await projectAPI.getProjectOptions()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchProjectShifts: AsyncThunk<
  ProjectShiftsPayload,
  string,
  AsyncThunkConfig
> = createAsyncThunk(
  'projects/fetchProjectShifts',
  async (projectId: string, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const projectAPI: ProjectAPI = new ProjectAPI(regionCode)
    try {
      const shifts: Shift[] = await projectAPI.getShiftsFromProject(projectId)
      return { projectId, shifts }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const applyProjectFilters: AsyncThunk<void, string, AsyncThunkConfig> =
  createAsyncThunk(
    'projects/applyProjectFilters',
    async (searchString: string, { getState, dispatch }) => {
      const state: AppState = getState() as AppState
      const { projects, projectOptions } = state.project
      const { filterSelections } = state.preferences

      // Convert filterSelections to ProjectOptions by keeping original SalesforcePicklistEntry objects
      const newSelections: ProjectOptions = {
        days: projectOptions.days.filter(
          (day: SalesforcePicklistEntry) => filterSelections.days[day.label],
        ),
        tags: projectOptions.tags.filter(
          (tag: SalesforcePicklistEntry) => filterSelections.tags[tag.label],
        ),
        locations: projectOptions.locations.filter(
          (location: SalesforcePicklistEntry) =>
            filterSelections.locations[location.label],
        ),
      }
      const projectValues: Project[] = Object.values(projects) as Project[]
      let filteredProjects: Project[] = projectValues

      // Check if any filter is applied
      const isAnyFilterApplied: boolean =
        !!searchString ||
        newSelections.locations.length > 0 ||
        newSelections.days.length > 0 ||
        newSelections.tags.length > 0
      // If any filter is applied, filter the projects
      if (isAnyFilterApplied) {
        filteredProjects = projectValues.filter((project: Project) => {
          const matchesLocation: boolean =
            newSelections.locations.length === 0 ||
            newSelections.locations.some((location: SalesforcePicklistEntry) =>
              project.Locations.includes(location.label),
            )
          const matchesDays: boolean =
            newSelections.days.length === 0 ||
            newSelections.days.some((day: SalesforcePicklistEntry) =>
              project.Days.includes(day.label),
            )
          const matchesTags: boolean =
            newSelections.tags.length === 0 ||
            newSelections.tags.some((tag: SalesforcePicklistEntry) =>
              project.Tags.includes(tag.label),
            )
          const matchesSearch: boolean = searchString
            ? project.Name.toLowerCase().includes(searchString)
            : true
          return matchesLocation && matchesDays && matchesTags && matchesSearch
        })
      }
      dispatch(setFilteredProjects(filteredProjects))
    },
  )

export const applyProjectFiltersPL: AsyncThunk<void, string, AsyncThunkConfig> =
  createAsyncThunk(
    'projects/applyProjectFiltersPL',
    async (searchString: string, { getState, dispatch }) => {
      const state: AppState = getState() as AppState
      const { projectsPL } = state.project

      // Convert filterSelections to ProjectOptions by keeping original SalesforcePicklistEntry objects
      const projectValues: ProjectLeaderProject[] = Object.values(
        projectsPL,
      ) as ProjectLeaderProject[]
      let filteredProjectsPL: ProjectLeaderProject[] = projectValues

      // Check if any search string is applied
      const isAnyFilterApplied: boolean = !!searchString
      // If any search string is applied, filter the projects
      if (isAnyFilterApplied) {
        filteredProjectsPL = projectValues.filter(
          (project: ProjectLeaderProject) => {
            return searchString
              ? project.Name.toLowerCase().includes(searchString)
              : true
          },
        )
      }
      dispatch(setFilteredProjectsPL(filteredProjectsPL))
    },
  )

export const fetchFilterInfo: AsyncThunk<boolean, void, AsyncThunkConfig> =
  createAsyncThunk('projects/fetchFilterInfo', async () => {
    const showFilterInfo = await getPreference('showFilterInfo')
    return showFilterInfo !== 'false'
  })

export const hideFilterInfo: AsyncThunk<void, void, AsyncThunkConfig> =
  createAsyncThunk('projects/hideFilterInfo', async () => {
    await setPreference('showFilterInfo', 'false')
  })

interface UpdateVolunteerShiftHourStatusPayload {
  projectId: string
  shiftId: string
  hourId: string
  status: VolunteerShiftHourStatus
}

interface UpdateVolunteerShiftAnnouncementPayload {
  projectId: string
  shiftId: string
  announcement: string
}

interface UpdateShiftRegistrationStatusPayload {
  projectId: string
  shiftId: string
  status: boolean
}

const projectsSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    updateProjectApplicationStatus: (
      state: WritableDraft<ProjectState>,
      action: PayloadAction<{
        projectId: string
        status: string
      }>,
    ) => {
      if (state.projects[action.payload.projectId]) {
        state.projects[action.payload.projectId].ApplicationStatus =
          action.payload.status
      } else {
        console.error(
          `projectSlice: Project with id ${action.payload.projectId} not found in projects object`,
        )
      }
    },
    updateVolunteerShiftHourStatusLocal: (
      state: WritableDraft<ProjectState>,
      action: PayloadAction<UpdateVolunteerShiftHourStatusPayload>,
    ) => {
      const { projectId, shiftId, hourId, status } = action.payload
      const shift: WritableDraft<ProjectLeaderShift> | undefined =
        state.projectShiftsPL[projectId]?.find(
          (shift: WritableDraft<ProjectLeaderShift>): boolean =>
            shift.ShiftId === shiftId,
        )
      if (shift) {
        const volunteer: WritableDraft<Volunteer> | undefined =
          shift.Volunteers.find(
            (vol: WritableDraft<Volunteer>): boolean => vol.HourId === hourId,
          )
        if (volunteer) {
          volunteer.Status = status
        }
      }
    },
    updateVolunteerShiftAnnouncementLocal: (
      state: WritableDraft<ProjectState>,
      action: PayloadAction<UpdateVolunteerShiftAnnouncementPayload>,
    ) => {
      const { projectId, shiftId, announcement } = action.payload
      const shift: WritableDraft<ProjectLeaderShift> | undefined =
        state.projectShiftsPL[projectId]?.find(
          (shift: WritableDraft<ProjectLeaderShift>): boolean =>
            shift.ShiftId === shiftId,
        )
      if (shift) {
        shift.Remark = announcement
      }
    },
    updateShiftRegistrationStatusLocal: (
      state: WritableDraft<ProjectState>,
      action: PayloadAction<UpdateShiftRegistrationStatusPayload>,
    ) => {
      const { projectId, shiftId, status } = action.payload
      const shift: WritableDraft<Shift> | undefined = state.projectShifts[
        projectId
        ]?.find((shift: Shift): boolean => shift.ShiftId === shiftId)
      if (shift) {
        shift.Registered = status
      }
    },
    updateShiftPresenceLocal: (
      state: WritableDraft<ProjectState>,
      action: PayloadAction<{
        projectId: string
        shiftId: string
        presence: boolean
      }>,
    ) => {
      const { projectId, shiftId, presence } = action.payload
      const shift: WritableDraft<Shift> | undefined = state.projectShifts[
        projectId
        ]?.find((shift: Shift): boolean => shift.ShiftId === shiftId)
      if (shift) {
        shift.NumberRegistered += presence ? 1 : -1
        shift.NumberNeededStill += presence ? -1 : 1
      }
    },
    setFilteredProjects: (
      state: WritableDraft<ProjectState>,
      action: PayloadAction<Project[]>,
    ) => {
      state.filteredProjects = action.payload
    },
    setFilteredProjectsPL: (
      state: WritableDraft<ProjectState>,
      action: PayloadAction<ProjectLeaderProject[]>,
    ) => {
      state.filteredProjectsPL = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state: WritableDraft<ProjectState>) => {
        state.isLoading = true
      })
      .addCase(
        fetchProjects.fulfilled,
        (
          state: WritableDraft<ProjectState>,
          action: PayloadAction<ProjectObject>,
        ) => {
          state.projects = action.payload
          state.isLoading = false
        },
      )
      .addCase(
        fetchProjects.rejected,
        (state: WritableDraft<ProjectState>, action) => {
          state.error = (action.error.message as string) || null
          state.isLoading = false
        },
      )
      .addCase(fetchProject.pending, (state: WritableDraft<ProjectState>) => {
        state.isLoading = true
      })
      .addCase(
        fetchProject.fulfilled,
        (state: WritableDraft<ProjectState>, action: PayloadAction<Project>) => {
          state.projects[action.payload.ProjectId] = action.payload
          state.isLoading = false
        },
      )
      .addCase(
        fetchProject.rejected,
        (state: WritableDraft<ProjectState>, action) => {
          state.error = (action.error.message as string) || null
          state.isLoading = false
        },
      )
      .addCase(applyToProject.pending, (state: WritableDraft<ProjectState>) => {
        state.isLoading = true
      })
      .addCase(
        applyToProject.fulfilled,
        (state: WritableDraft<ProjectState>) => {
          state.isLoading = false
        },
      )
      .addCase(
        applyToProject.rejected,
        (state: WritableDraft<ProjectState>, action) => {
          state.error = (action.error.message as string) || null
          state.isLoading = false
        },
      )
      .addCase(
        fetchProjectsPL.pending,
        (state: WritableDraft<ProjectState>) => {
          state.isLoading = true
        },
      )
      .addCase(
        fetchProjectsPL.fulfilled,
        (
          state: WritableDraft<ProjectState>,
          action: PayloadAction<ProjectLeaderProject[]>,
        ) => {
          state.projectsPL = action.payload
          state.isLoading = false
        },
      )
      .addCase(
        fetchProjectsPL.rejected,
        (state: WritableDraft<ProjectState>, action) => {
          state.error = (action.error.message as string) || null
          state.isLoading = false
        },
      )
      .addCase(fetchShiftsPL.pending, (state: WritableDraft<ProjectState>) => {
        state.isLoading = true
      })
      .addCase(
        fetchShiftsPL.fulfilled,
        (
          state: WritableDraft<ProjectState>,
          action: PayloadAction<ProjectLeaderShiftsPayload>,
        ) => {
          state.projectShiftsPL[action.payload.projectId] =
            action.payload.shiftsPL
          state.isLoading = false
        },
      )
      .addCase(
        fetchShiftsPL.rejected,
        (state: WritableDraft<ProjectState>, action) => {
          state.error = (action.error.message as string) || null
          state.isLoading = false
        },
      )
      .addCase(
        fetchProjectOptions.pending,
        (state: WritableDraft<ProjectState>) => {
          state.isLoading = true
        },
      )
      .addCase(
        fetchProjectOptions.fulfilled,
        (
          state: WritableDraft<ProjectState>,
          action: PayloadAction<ProjectOptions>,
        ) => {
          state.projectOptions = action.payload
          state.isLoading = false
        },
      )
      .addCase(
        fetchProjectOptions.rejected,
        (state: WritableDraft<ProjectState>, action) => {
          state.error = (action.error.message as string) || null
          state.isLoading = false
        },
      )
      .addCase(
        fetchProjectShifts.pending,
        (state: WritableDraft<ProjectState>) => {
          state.isLoading = true
        },
      )
      .addCase(
        fetchProjectShifts.fulfilled,
        (
          state: WritableDraft<ProjectState>,
          action: PayloadAction<ProjectShiftsPayload>,
        ) => {
          state.projectShifts[action.payload.projectId] = action.payload.shifts
          state.isLoading = false
        },
      )
      .addCase(
        fetchProjectShifts.rejected,
        (state: WritableDraft<ProjectState>, action) => {
          state.error = (action.error.message as string) || null
          state.isLoading = false
        },
      )
      .addCase(
        fetchFilterInfo.fulfilled,
        (
          state: WritableDraft<ProjectState>,
          action: PayloadAction<boolean>,
        ) => {
          state.showFilterInfo = action.payload
        },
      )
      .addCase(
        hideFilterInfo.fulfilled,
        (state: WritableDraft<ProjectState>) => {
          state.showFilterInfo = false
        },
      )
      .addCase(
        hideFilterInfo.rejected,
        (state: WritableDraft<ProjectState>, action) => {
          state.error = (action.error.message as string) || null
        },
      )
  },
})

export const {
  updateProjectApplicationStatus,
  updateVolunteerShiftHourStatusLocal,
  updateVolunteerShiftAnnouncementLocal,
  updateShiftRegistrationStatusLocal,
  updateShiftPresenceLocal,
  setFilteredProjects,
  setFilteredProjectsPL,
} = projectsSlice.actions

export default projectsSlice.reducer
