import React from 'react'
import { Trans } from 'react-i18next'

const AuthTermsAndConditions: React.FC = () => {
  return (
    <div className="ion-text-center">
      <span>
        <small>
          <Trans i18nKey="login.disclaimer">
            By signing up or logging in, you agree to our <a href="https://www.servenow.app/privacy-policy">Privacy Policy</a> and our
            <a href="https://www.servenow.app/terms-and-conditions">Terms and Conditions</a>.
          </Trans>
        </small>
      </span>
    </div>
  )
}

export default AuthTermsAndConditions
