// src/store/redux/slices/preferencesSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectOptionsLabels } from '../../../types/types'
import { WritableDraft } from 'immer'
import { PURGE } from 'redux-persist/es/constants'

export type FilterSelections = {
  [key in ProjectOptionsLabels]: FilterState
}

interface FilterState {
  [key: string]: boolean
}

export interface PreferencesState {
  filterSelections: FilterSelections
  test: string
  isLoading: boolean
  error: string | null
}

export const initialState: PreferencesState = {
  filterSelections: {
    days: {},
    tags: {},
    locations: {},
  },
  test: '',
  isLoading: false,
  error: null,
}

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    toggleFilterItem: (
      state: WritableDraft<PreferencesState>,
      action: PayloadAction<{ category: ProjectOptionsLabels; label: string }>,
    ) => {
      const { category, label } = action.payload
      const isSelected: boolean = state.filterSelections[category]?.[label] || false
      state.filterSelections[category][label] = !isSelected
      state.test = 'TEST'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
  },
})

export const { toggleFilterItem } = preferencesSlice.actions
export default preferencesSlice.reducer
