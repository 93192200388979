import React, { createContext, useState, useContext, useEffect } from 'react'

import { getPreference, setPreference } from '../../utils/preferencesStorage'
import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'

const ThemeContext = createContext<any>({
  darkMode: false,
  toggleDarkMode: (isDarkMode: boolean) => {},
})

type Props = {
  children?: React.ReactNode
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [darkMode, setDarkMode] = useState<boolean>(false)

  const toggleDarkMode = async (isDarkMode: boolean) => {
    document.body.classList.toggle('dark', isDarkMode)
    setDarkMode(isDarkMode)

    //Status Bar on iOS
    if (
      Capacitor.getPlatform() !== 'web' &&
      Capacitor.getPlatform() === 'ios'
    ) {
      if (isDarkMode) {
        StatusBar.setStyle({ style: Style.Dark })
      } else {
        StatusBar.setStyle({ style: Style.Light })
      }
    }

    await setPreference('serveNowDarkMode', isDarkMode.toString())
  }

  useEffect(() => {
    const initialiseTheme = async () => {
      const storedThemePreference = await getPreference('serveNowDarkMode')
      const isDarkMode = storedThemePreference === 'true'
      document.body.classList.toggle('dark', isDarkMode)
      setDarkMode(isDarkMode)
    }
    initialiseTheme().then((r) => r)
  }, [])

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  )
}

const useTheme = () => useContext(ThemeContext)

export { useTheme, ThemeProvider }
