import { IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react'
import React, { useRef, useEffect, useCallback } from 'react'
// Swiper
import type { Swiper as SwiperInterface } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import '@ionic/react/css/ionic-swiper.css'

import LoginPage from '../auth/LoginPage'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/redux/types'
import { RegionState } from '../../store/redux/slices/regionSlice'
import WelcomeSlide from './WelcomeSlide'
import WelcomeSelectRegionSlide from './WelcomeSelectRegionSlide'
import WelcomeRegionInformationSlide from './WelcomeRegionInformationSlide'
import WelcomeAlmostThereSlide from './WelcomeAlmostThereSlide'

const WelcomePage: React.FC = () => {
  const {
    name,
    // selectedLanguage
  } = useSelector((state: AppState): RegionState => state.region)
  const swiperRef = useRef<SwiperInterface | null>(null)
  const contentRef = useRef<HTMLIonContentElement | null>(null)

  const selectedLanguage = 'en'

  const scrollToTop = () => {
    contentRef.current?.scrollToTop(500)
  }

  const checkSwiper = (): void => {
    const currentSlide: number | undefined = swiperRef.current?.activeIndex
    // swiperRef.current!.allowSlideNext = !(currentSlide === 1 && (!name || !selectedLanguage))
    swiperRef.current!.allowSlideNext = !(currentSlide === 1 && !name)
  }

  const handleSlideNext = useCallback(() => {
    if (name && selectedLanguage && swiperRef.current) {
      swiperRef.current.slideNext()
      scrollToTop()
    }
  }, [name, selectedLanguage])

  useEffect(() => {
    checkSwiper()
  }, [name, selectedLanguage])

  useEffect(() => {
    handleSlideNext()
  }, [name, selectedLanguage, handleSlideNext])

  return (
    <IonPage className="loginScreen">
      <IonContent className="ion-padding" fullscreen ref={contentRef}>
        <IonGrid>
          <IonRow>
            <IonCol size="12" sizeMd="6" offsetMd="3">
              <Swiper
                onSwiper={(swiper: SwiperInterface) => {
                  swiperRef.current = swiper
                  checkSwiper()
                }}
                initialSlide={0}
                noSwipingClass={'swiper-no-swiping'}
                onSlideChangeTransitionEnd={checkSwiper}
              >
                <SwiperSlide>
                  <WelcomeSlide
                    swiperInstance={swiperRef.current!}
                    scrollToTop={scrollToTop}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <WelcomeSelectRegionSlide
                    swiperInstance={swiperRef.current!}
                    scrollToTop={scrollToTop}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <WelcomeRegionInformationSlide
                    swiperInstance={swiperRef.current!}
                    scrollToTop={scrollToTop}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <WelcomeAlmostThereSlide
                    swiperInstance={swiperRef.current!}
                    scrollToTop={scrollToTop}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <LoginPage />
                </SwiperSlide>
              </Swiper>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default WelcomePage
