// src/hooks/useStatusBarStyle.ts

import { Capacitor } from '@capacitor/core'
import { StatusBar, Style } from '@capacitor/status-bar'

const useStatusBarStyle = () => {
  return (): void => {
    if (Capacitor.getPlatform() !== 'web' && Capacitor.getPlatform() === 'ios') {
      if (document.body.classList.contains('dark')) {
        StatusBar.setStyle({ style: Style.Dark }).then(() => {
          // Handle success if needed
        }).catch(err => console.error('Failed to set status bar style:', err))
      } else {
        StatusBar.setStyle({ style: Style.Light }).then(() => {
          // Handle success if needed
        }).catch(err => console.error('Failed to set status bar style:', err))
      }
    }
  }
}

export default useStatusBarStyle
