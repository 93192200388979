// src/store/redux/slices/profileSlice.ts

import { WritableDraft } from 'immer'
import { createSlice, createAsyncThunk, PayloadAction, AsyncThunk } from '@reduxjs/toolkit'
import { Profile, ProfileOptions, User } from '../../../types/interfaces'
import { ProfileAPI } from '../../../api/ProfileAPI'
import { defaultUser } from '../../../constants/defaults'
import { AppState } from '../types'
import { getRegionCode } from '../utils/getRegionCode'
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'

export interface ProfileState {
  profile: User;
  profileOptions: ProfileOptions | null;
  isLoading: boolean;
  isSaving: boolean;
  error: string | null;
}

export const initialState: ProfileState = {
  profile: defaultUser,
  profileOptions: null,
  isLoading: false,
  isSaving: false,
  error: null,
}

/**
 * Fetch the profile for the current user
 * @returns {Promise<User>} - The profile for the current user
 */
export const fetchProfile: AsyncThunk<User, void, AsyncThunkConfig> = createAsyncThunk(
  'profile/fetchProfile',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const profileAPI: ProfileAPI = new ProfileAPI(regionCode)
    try {
      return await profileAPI.getProfile()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Update the profile for the current user
 * @param {Partial<Profile>} profileData - The profile data to update
 * @returns {Promise<User>} - The updated user
 */
export const updateProfile: AsyncThunk<User, Partial<Profile>, AsyncThunkConfig> = createAsyncThunk(
  'profile/updateProfile',
  async (profileData: Partial<Profile>, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const profileAPI: ProfileAPI = new ProfileAPI(regionCode)
    try {
      const response: boolean = await profileAPI.updateProfile(profileData)
      if (response) {
        return await profileAPI.getProfile()
      } else {
        return rejectWithValue('Failed to update profile')
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Fetch the profile options
 * @returns {Promise<ProfileOptions>} - The profile options
 */
export const fetchProfileOptions: AsyncThunk<ProfileOptions, void, AsyncThunkConfig> = createAsyncThunk(
  'profile/fetchProfileOptions',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const profileAPI: ProfileAPI = new ProfileAPI(regionCode)
    try {
      return await profileAPI.getProfileOptions()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

/**
 * Delete the profile for the current user
 * @returns {Promise<string>} - The response from the API
 */
export const deleteProfile: AsyncThunk<string, void, AsyncThunkConfig> = createAsyncThunk(
  'profile/deleteProfile',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const profileAPI: ProfileAPI = new ProfileAPI(regionCode)
    try {
      return await profileAPI.deleteProfile()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state: WritableDraft<ProfileState>): void => {
        state.isLoading = true
      })
      .addCase(fetchProfile.fulfilled, (state: WritableDraft<ProfileState>, action: PayloadAction<User>): void => {
        state.profile = action.payload
        state.isLoading = false
      })
      .addCase(fetchProfile.rejected, (state: WritableDraft<ProfileState>, action): void => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })

      .addCase(updateProfile.pending, (state: WritableDraft<ProfileState>): void => {
        state.isSaving = true
      })
      .addCase(updateProfile.fulfilled, (state: WritableDraft<ProfileState>, action: PayloadAction<User>): void => {
        state.profile = action.payload
        state.isSaving = false
      })
      .addCase(updateProfile.rejected, (state: WritableDraft<ProfileState>, action): void => {
        state.error = action.error.message as string || null
        state.isSaving = false
      })

      .addCase(fetchProfileOptions.pending, (state: WritableDraft<ProfileState>): void => {
        state.isLoading = true
      })
      .addCase(fetchProfileOptions.fulfilled, (state: WritableDraft<ProfileState>, action: PayloadAction<ProfileOptions>): void => {
        state.profileOptions = action.payload
        state.isLoading = false
      })
      .addCase(fetchProfileOptions.rejected, (state: WritableDraft<ProfileState>, action): void => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })

      .addCase(deleteProfile.pending, (state: WritableDraft<ProfileState>): void => {
        state.isSaving = true
      })
      .addCase(deleteProfile.fulfilled, (state: WritableDraft<ProfileState>): void => {
        state.profile = defaultUser
        state.isSaving = false
      })
      .addCase(deleteProfile.rejected, (state: WritableDraft<ProfileState>, action): void => {
        state.error = action.error.message as string || null
        state.isSaving = false
      })
  },
})

export default profileSlice.reducer
