// src/store/redux/rehydrateReducer.ts

import { Reducer, UnknownAction } from '@reduxjs/toolkit'

export const rehydrateReducer = <S>(reducer: Reducer<S>, initialState: S, sliceName: string): Reducer<S> => {
  return (state: S | undefined, action: UnknownAction): S => {
    if (action.type === 'persist/REHYDRATE' && action.payload) {
      const payload = action.payload as { [key: string]: any }
      const sliceState = payload[sliceName]
      if (sliceState) {
        return { ...initialState, ...sliceState }
      }
    }
    return reducer(state, action)
  }
}
