// src/api/ApplicationAPI.ts

import { API_PATH } from '../constants/defaults'
import { BaseAPI } from './BaseAPI'
import { Application, ApplicationData } from '../types/interfaces'
import { ApplicationUpdateAction } from '../types/types'

/**
 * ApplicationAPI class
 * @classdesc Class for interacting with the Application API
 */
export class ApplicationAPI extends BaseAPI {

  /**
   * Get the applications
   * @returns {Promise<Application[]>} - The applications
   */
  public async getApplications(): Promise<Application[]> {
    try {
      return this.api.get(API_PATH.PROJECT_LEADER_APPLICATIONS).then(response => response.data)
    } catch (error) {
      throw error
    }
  }

  /**
   * Get the applications for a specific project
   * @param projectId {string} - The project ID
   * @returns {Promise<Application[]>} - The applications
   */
  public async getProjectApplications(projectId: string): Promise<Application[]> {
    try {
      return this.api.get(`${API_PATH.PROJECT_LEADER_APPLICATIONS}/${projectId}`).then(response => response.data)
    } catch (error) {
      throw error
    }
  }

  /**
   * Update the application
   * @param applicationId {string} - The application ID
   * @param data {ApplicationData} - The application data
   * @returns {Promise<string>} - The success message
   */
  public async updateApplication(applicationId: string, data: ApplicationData): Promise<string> {
    try {
      return this.api.patch(`${API_PATH.PROJECT_LEADER_APPLICATIONS}/${applicationId}/update`, data)
        .then(response => response.data)
    } catch (error) {
      throw error
    }
  }

  /**
   * Update the application based on a specific action
   * @param applicationId {string} - The application ID
   * @param action {ApplicationUpdateAction} - The action to perform
   * @returns {Promise<string>} - The success message
   */
  public async updateApplicationAction(applicationId: string, action: ApplicationUpdateAction): Promise<string> {
    try {
      return this.api.patch(`${API_PATH.PROJECT_LEADER_APPLICATIONS}/${applicationId}/${action}`).then(response => response.data)
    } catch (error) {
      throw error
    }
  }
}
