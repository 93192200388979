// src/store/redux/slices/regionSlice.ts

import { createSlice, PayloadAction, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer'
import { Language } from '../../../types/interfaces'
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { AppState } from '../types'
import { getRegionCode } from '../utils/getRegionCode'
import { RegionAPI } from '../../../api/RegionAPI'
import { LanguageCode } from '../../../types/types'
import { PURGE } from 'redux-persist/es/constants'

export interface RegionState {
  name: string;
  apiCode: string;
  code: string;
  isLoading: boolean;
  languages: Language[];
  selectedLanguage: LanguageCode | null;
  error: string;
}

export const initialState: RegionState = {
  name: '',
  apiCode: '',
  code: '',
  isLoading: false,
  languages: [],
  selectedLanguage: null,
  error: '',
}

export const fetchLanguages: AsyncThunk<Language[], void, AsyncThunkConfig> = createAsyncThunk(
  'region/fetchLanguages',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const regionAPI: RegionAPI = new RegionAPI(regionCode)
    try {
      return await regionAPI.getLanguages()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export interface SetRegionPayload {
  name: string;
  apiCode: string;
  code: string;
}

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setRegion(state: WritableDraft<RegionState>, action: PayloadAction<SetRegionPayload>) {
      state.apiCode = action.payload.apiCode
      state.code = action.payload.code
      state.name = action.payload.name
    },
    setLanguage(state: WritableDraft<RegionState>, action: PayloadAction<LanguageCode | null>) {
      state.selectedLanguage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(fetchLanguages.pending, (state: WritableDraft<RegionState>) => {
        state.isLoading = true
      })
      .addCase(fetchLanguages.fulfilled, (state: WritableDraft<RegionState>, action: PayloadAction<Language[]>) => {
        state.languages = action.payload
        state.isLoading = false
      })
      .addCase(fetchLanguages.rejected, (state: WritableDraft<RegionState>, action) => {
        state.error = action.error.message as string || ''
        state.isLoading = false
      })
  },
})

export const {
  setRegion,
  setLanguage,
} = regionSlice.actions
export default regionSlice.reducer
