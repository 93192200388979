import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { AuthState, updateEmail } from '../../store/redux/slices/authSlice'
import { useTranslation } from 'react-i18next'
import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from '@ionic/react'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/redux/types'
import { RegionApiCodes } from '../../types/types'
import FooterCommon from '../../components/FooterCommon'

interface UpdateEmailParams {
  regionApiCode: RegionApiCodes
  id: string
  oldEmail: string
  newEmail: string
}

interface Payload {
  response: {
    status: number
  }
}

const UpdateEmail: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector((state: AppState): AuthState => state.auth)
  const { regionApiCode, id, oldEmail, newEmail } = useParams<UpdateEmailParams>()
  const [message, setMessage] = useState('')
  const { t } = useTranslation()

  const hasTriedToUpdateEmail = useRef(false)
  useEffect(() => {
    if (!hasTriedToUpdateEmail.current) {
      hasTriedToUpdateEmail.current = true
      dispatch(updateEmail({ regionApiCode, id, oldEmail, newEmail })).then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          setMessage(t('updateEmail.success'))
        } else {
          const payload: Payload = result.payload as Payload
          switch (payload.response.status) {
            case 400:
              setMessage(t('updateEmail.malformed'))
              break
            case 404:
              setMessage(t('updateEmail.notFound'))
              break
            default:
              setMessage(t('updateEmail.error'))
              break
          }
        }
      })
    }
  }, [id, oldEmail, newEmail, dispatch, t])

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol class="text-center">
              <IonImg class="logo mx-auto ion-padding-vertical" src="/assets/images/servenow-light.svg" alt="" />
              <h1 className="ion-padding-vertical">{t('updateEmail.title')}</h1>
              {isLoading ? <p>{t('loading.loading')}</p> : <p>{message}</p>}
            </IonCol>
          </IonRow>
        </IonGrid>
        <FooterCommon></FooterCommon>
      </IonContent>
    </IonPage>
  )
}

export default UpdateEmail
