import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './i18n'
import AppProviders from './store'
import { Provider } from 'react-redux'
import { persistor, store } from './store/redux/store'
import { PersistGate } from 'redux-persist/integration/react'

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <AppProviders>
    <StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <App />
        </PersistGate>
      </Provider>
    </StrictMode>
  </AppProviders>,
)
