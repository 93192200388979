// src/api/RegionAPI.ts

import { BaseAPI } from './BaseAPI'
import { Language } from '../types/interfaces'
import { API_PATH, defaultLanguages } from '../constants/defaults'

/**
 * RegionAPI class
 * @classdesc Class for interacting with the region API
 */
export class RegionAPI extends BaseAPI {
  /**
   * Get the languages
   * @returns {Promise<Language[]>} - The languages
   */
  public async getLanguages(): Promise<Language[]> {
    // Uncomment the following code when the API is available
    // try {
    //   return this.api.get(API_PATH.LANGUAGES).then(response => response.data)
    // } catch (error) {
    //   throw error
    // }

    // Remove the following code when the API is available
    return new Promise<Language[]>((resolve): void => {
      resolve(defaultLanguages)
    })
  }
}
