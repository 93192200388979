import ToastNotification from './components/ToastNotification'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { useAppDispatch } from './hooks/reduxHooks'
import { AppState } from './store/redux/types'
import { useSelector } from 'react-redux'
import { AuthState, initialiseUser, logout } from './store/redux/slices/authSlice'
import { getPreference, setPreference } from './utils/preferencesStorage'
import { persistor } from './store/redux/store'

import { loadConfig } from './utils/loadConfig'

import LoadingFallback from './components/LoadingFallback'
import ErrorDisplay from './components/ErrorDisplay'
import ActivateAccount from './features/auth/ActivateAccount'
import UpdatePasswordForm from './features/auth/UpdatePasswordForm'
import UpdateEmail from './features/auth/UpdateEmail'

// Lazy loading is applied to the AuthenticatedApp to optimize initial load time
// for unauthenticated users, since it is the larger part of the app.
// The UnauthenticatedApp is not lazy-loaded to ensure the login/signup UI
// is rendered immediately, which helps avoid potential CSS loading issues
// experienced when both were lazy-loaded.

const AuthenticatedApp = React.lazy(() => import('./features/auth/AuthenticatedApp'))
import UnauthenticatedApp from './features/auth/UnauthenticatedApp'

const Main: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isAuth } = useSelector((state: AppState): AuthState => state.auth)
  const location = useLocation()
  const [isInitialized, setIsInitialized] = useState(false)

  const hasFetchedUser = useRef(false)
  useEffect(() => {
    // Define paths that should skip user initialization
    const skipInitPaths = ['/activate', '/update-password', '/update-email']
    const shouldSkipInit = skipInitPaths.some((path) => location.pathname.startsWith(path))
    if (shouldSkipInit) {
      setIsInitialized(true)
      return
    }

    // Did we already set a variable for version 2.2 or are we upgrading from an earlier version?
    async function initializeApp() {
      const isVersionSet = await getPreference('serveNowVersion22')
      await loadConfig()
      if (isVersionSet) {
        if (!hasFetchedUser.current) {
          await dispatch(initialiseUser())
          hasFetchedUser.current = true
        }
      } else {
        await dispatch(logout())
        await persistor.purge()
        await setPreference('serveNowVersion22', true)
      }
      setIsInitialized(true)
    }

    initializeApp().then(r => r)
  }, [dispatch])

  return (
    <>
      {!isInitialized ? (
        <></>
      ) : (
        <>
          <ErrorDisplay />
          <ToastNotification />
          <Suspense fallback={<LoadingFallback />}>
            <Switch>
              <Route path="/activate/:regionApiCode/:email/:token" component={ActivateAccount} />
              <Route path="/update-password/:regionApiCode/:email/:token" component={UpdatePasswordForm} />
              <Route path="/update-email/:regionApiCode/:id/:oldEmail/:newEmail" component={UpdateEmail} />
              <Route path="/" component={isAuth ? AuthenticatedApp : UnauthenticatedApp} />
            </Switch>
          </Suspense>
        </>
      )}
    </>
  )
}

export default Main
