// src/store/redux/slices/shiftSlice.ts

import { createSlice, createAsyncThunk, PayloadAction, AsyncThunk, createSelector } from '@reduxjs/toolkit'
import { AppState } from '../types'
import { ShiftAPI } from '../../../api/ShiftAPI'
import { WritableDraft } from 'immer'
import { getRegionCode } from '../utils/getRegionCode'
import { Shift } from '../../../types/interfaces'
import { ShiftProjectLeaders, VolunteerShiftHourStatus } from '../../../types/types'
import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk'
import {
  updateShiftPresenceLocal,
  updateShiftRegistrationStatusLocal,
  updateVolunteerShiftAnnouncementLocal,
  updateVolunteerShiftHourStatusLocal,
} from './projectSlice'

interface ShiftObject {
  [key: string]: Shift
}

interface ShiftProjectLeadersObject {
  [key: string]: ShiftProjectLeaders
}

export interface ShiftState {
  shifts: ShiftObject;
  shiftProjectLeaders: ShiftProjectLeadersObject;
  isLoading: boolean;
  error: string | null;
}

export const initialState: ShiftState = {
  shifts: {},
  shiftProjectLeaders: {},
  isLoading: false,
  error: null,
}

interface FetchShiftsPayload {
  shiftId: string
  shift: Shift
}

interface ShiftsProjectLeadersPayload {
  shiftId: string;
  shiftProjectLeaders: ShiftProjectLeaders;
}

interface UpdateVolunteerShiftHourStatusPayload {
  projectId: string;
  shiftId: string;
  hourId: string;
  status: VolunteerShiftHourStatus
}

interface UpdateVolunteerShiftAnnouncementPayload {
  projectId: string;
  shiftId: string;
  announcement: string;
}

export const getRegisteredShifts = createSelector(
  (state: AppState) => state.shift.shifts,
  (shifts: ShiftObject) => {
    // Convert the object of shifts to an array and filter for registered shifts
    return Object.values(shifts).filter((shift: Shift) => shift.Registered)
  },
)

export const fetchShifts: AsyncThunk<ShiftObject, void, AsyncThunkConfig> = createAsyncThunk(
  'shifts/fetchShifts',
  async (_, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      const shifts: Shift[] = await shiftAPI.getShifts()
      // Convert the shifts array to an object for easier access and manipulation
      return shifts.reduce((acc: ShiftObject, shift: Shift) => {
        acc[shift.ShiftId] = shift
        return acc
      }, {})
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchShift: AsyncThunk<FetchShiftsPayload, string, AsyncThunkConfig> = createAsyncThunk(
  'shifts/fetchShift',
  async (shiftId: string, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      const shift: Shift = await shiftAPI.getShift(shiftId)
      return { shiftId, shift }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchShiftsProjectLeaders: AsyncThunk<ShiftsProjectLeadersPayload, string, AsyncThunkConfig> = createAsyncThunk(
  'shifts/fetchShiftsProjectLeaders',
  async (shiftId: string, { getState, rejectWithValue }) => {
    const regionCode: string = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      const shiftProjectLeaders: ShiftProjectLeaders = await shiftAPI.getShiftProjectLeaders(shiftId)
      return { shiftId, shiftProjectLeaders }
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateVolunteerShiftHourStatus: AsyncThunk<undefined, UpdateVolunteerShiftHourStatusPayload, AsyncThunkConfig> = createAsyncThunk(
  'shifts/updateVolunteerShiftHourStatus',
  async (payload: UpdateVolunteerShiftHourStatusPayload, { getState, dispatch, rejectWithValue }) => {
    const { projectId, shiftId, hourId, status } = payload
    const regionCode: string = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.updateVolunteerShiftHourStatus(shiftId, hourId, status)
      dispatch(updateVolunteerShiftHourStatusLocal({ projectId, shiftId, hourId, status }))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateVolunteerShiftAnnouncement: AsyncThunk<undefined, UpdateVolunteerShiftAnnouncementPayload, AsyncThunkConfig> = createAsyncThunk(
  'shifts/updateVolunteerShiftAnnouncement',
  async (payload: UpdateVolunteerShiftAnnouncementPayload, { getState, dispatch, rejectWithValue }) => {
    const { projectId, shiftId, announcement } = payload
    const regionCode: string = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.updateVolunteerShiftAnnouncement(shiftId, announcement)
      dispatch(updateVolunteerShiftAnnouncementLocal({ projectId, shiftId, announcement }))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

interface RegisterUnregisterPayload {
  shiftId: string;
  projectId: string;
}

export const registerToShift: AsyncThunk<undefined, RegisterUnregisterPayload, AsyncThunkConfig> = createAsyncThunk(
  'shifts/registerToShift',
  async (payload: RegisterUnregisterPayload, { getState, dispatch, rejectWithValue }) => {
    const { shiftId, projectId } = payload
    const regionCode: string = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.registerToShift(shiftId)
      dispatch(updateShiftRegistrationStatusLocal({ projectId, shiftId, status: true }))
      dispatch(updateShiftPresenceLocal({ projectId, shiftId, presence: true }))
      dispatch(fetchShifts())
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const unregisterFromShift: AsyncThunk<undefined, RegisterUnregisterPayload, AsyncThunkConfig> = createAsyncThunk(
  'shifts/unregisterFromShift',
  async (payload: RegisterUnregisterPayload, { getState, dispatch, rejectWithValue }) => {
    const { shiftId, projectId } = payload
    const regionCode: string = getRegionCode(getState() as AppState)
    const shiftAPI: ShiftAPI = new ShiftAPI(regionCode)
    try {
      await shiftAPI.unregisterFromShift(shiftId)
      dispatch(updateShiftRegistrationStatusLocal({ projectId, shiftId, status: false }))
      dispatch(updateShiftPresenceLocal({ projectId, shiftId, presence: false }))
      dispatch(fetchShifts())
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const shiftsSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShifts.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(fetchShifts.fulfilled, (state: WritableDraft<ShiftState>, action: PayloadAction<ShiftObject>) => {
        state.shifts = action.payload
        state.isLoading = false
      })
      .addCase(fetchShifts.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })
      .addCase(fetchShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(fetchShift.fulfilled, (state: WritableDraft<ShiftState>, action: PayloadAction<FetchShiftsPayload>) => {
        state.shifts[action.payload.shiftId] = action.payload.shift
        state.isLoading = false
      })
      .addCase(fetchShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })
      .addCase(fetchShiftsProjectLeaders.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(fetchShiftsProjectLeaders.fulfilled, (state: WritableDraft<ShiftState>, action: PayloadAction<ShiftsProjectLeadersPayload>) => {
        state.shiftProjectLeaders[action.payload.shiftId] = action.payload.shiftProjectLeaders
        state.isLoading = false
      })
      .addCase(fetchShiftsProjectLeaders.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })
      .addCase(updateVolunteerShiftHourStatus.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(updateVolunteerShiftHourStatus.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(updateVolunteerShiftHourStatus.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })
      .addCase(updateVolunteerShiftAnnouncement.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(updateVolunteerShiftAnnouncement.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(updateVolunteerShiftAnnouncement.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })
      .addCase(registerToShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(registerToShift.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(registerToShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })
      .addCase(unregisterFromShift.pending, (state: WritableDraft<ShiftState>) => {
        state.isLoading = true
      })
      .addCase(unregisterFromShift.fulfilled, (state: WritableDraft<ShiftState>) => {
        state.isLoading = false
      })
      .addCase(unregisterFromShift.rejected, (state: WritableDraft<ShiftState>, action) => {
        state.error = action.error.message as string || null
        state.isLoading = false
      })
  },
})

export default shiftsSlice.reducer
