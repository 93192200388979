import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { Capacitor } from '@capacitor/core'
import { API_BASE_REGION } from '../../constants/defaults'
import { arrowBack, arrowForward } from 'ionicons/icons'
import React, { useEffect, useState } from 'react'
import {
  fetchLanguages,
  RegionState,
  // setLanguage,
  setRegion,
  SetRegionPayload,
} from '../../store/redux/slices/regionSlice'
import { useAppDispatch } from '../../hooks/reduxHooks'
import Swiper from 'swiper'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/redux/types'
import { Region } from '../../types/interfaces'
// import { Language } from '../../types/interfaces'
// import { LanguageCode } from '../../types/types'
import { loadConfig } from '../../utils/loadConfig'

interface WelcomeSelectRegionSlideProps {
  swiperInstance: Swiper
  scrollToTop: () => void
}

const WelcomeSelectRegionSlide: React.FC<WelcomeSelectRegionSlideProps> = ({
  swiperInstance,
  scrollToTop,
}) => {
  const dispatch = useAppDispatch()
  const {
    name,
    // languages,
    // selectedLanguage,
    // isLoading,
  } = useSelector((state: AppState): RegionState => state.region)
  const { t } = useTranslation()
  const [config, setConfig] = useState<any>(null)

  useEffect(() => {
    loadConfig().then((cfg) => {
      setConfig(cfg)
    })
  }, [])

  const selectRegionHandler = (value: string): void => {
    const selectedRegion: Region | undefined = API_BASE_REGION.find(
      (el: Region): boolean => el.code === value,
    )
    const regionData: SetRegionPayload = {
      name: selectedRegion?.name || '',
      apiCode: selectedRegion?.apiCode || '',
      code: selectedRegion?.code || '',
    }
    if (selectedRegion) {
      dispatch(setRegion(regionData))
      // dispatch(setLanguage(null))
      dispatch(fetchLanguages())
    }
  }

  // const selectRegionLanguageHandler = (value: LanguageCode): void => {
  //   dispatch(setLanguage(value))
  // }

  return (
    <IonGrid className="loginScreen__region">
      <IonRow className="fullwidth">
        <IonCol
          className="ion-text-center"
          size-sm="8"
          offset-sm="2"
          size-md="6"
          offset-md="3"
          size-lg="4"
          offset-lg="4"
        >
          {Capacitor.getPlatform() === 'ios' && <div>&nbsp;</div>}
          <h2>{t('login.selectRegionTitle')}</h2>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <p>{t('login.selectRegion')}</p>

          <img
            src="/assets/images/login-select-region.svg"
            alt=""
            className="login"
          />
        </IonCol>
      </IonRow>
      <IonRow className="fullwidth">
        <IonCol className="ion-text-center" size="12" offset-sm="0">
          <IonSelect
            label={t('login.selectRegionLabel')}
            interface="popover"
            className="ion-margin-top"
            interfaceOptions={{
              cssClass: 'custom-dropdown custom-dropdown--login',
            }}
            onIonChange={(e) => selectRegionHandler(e.detail.value)}
          >
            {API_BASE_REGION.map((region: Region) =>
              (config && config.ALLOW_SANDBOX) ||
              (region.code !== 'sandbox' && region.code !== 'vmm') ? (
                <IonSelectOption
                  className="regionOption"
                  key={region.code}
                  value={region.code}
                >
                  {region.name}
                </IonSelectOption>
              ) : null,
            )}
          </IonSelect>
          {/*<IonSelect*/}
          {/*  label={t('login.selectLanguageLabel')}*/}
          {/*  interface="popover"*/}
          {/*  className="ion-margin-top"*/}
          {/*  interfaceOptions={{*/}
          {/*    cssClass: 'custom-dropdown custom-dropdown--login',*/}
          {/*  }}*/}
          {/*  onIonChange={(e) => selectRegionLanguageHandler(e.detail.value)}*/}
          {/*  disabled={!name || isLoading}*/}
          {/*  value={selectedLanguage}*/}
          {/*>*/}
          {/*  {languages.map((language: Language) => (*/}
          {/*    <IonSelectOption className="regionOption" key={language.code} value={language.code}>*/}
          {/*      {language.name}*/}
          {/*    </IonSelectOption>*/}
          {/*  ))}*/}
          {/*</IonSelect>*/}
        </IonCol>
      </IonRow>
      <IonRow className="ion-padding-top fullwidth footer">
        <IonCol className="ion-text-left" size="6">
          <IonButton
            fill="clear"
            onClick={(): void => {
              swiperInstance!.slidePrev()
              scrollToTop()
            }}
          >
            {t('btn.back')}{' '}
            <IonIcon icon={arrowBack} slot="start" aria-hidden={true}></IonIcon>
          </IonButton>
        </IonCol>
        <IonCol className="ion-text-right" size="6">
          <IonButton
            // disabled={!name || !selectedLanguage}
            disabled={!name}
            fill="clear"
            onClick={(): void => {
              swiperInstance!.slideNext()
              scrollToTop()
            }}
          >
            {t('btn.continue')}{' '}
            <IonIcon
              icon={arrowForward}
              slot="end"
              aria-hidden={true}
            ></IonIcon>
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
}

export default WelcomeSelectRegionSlide
