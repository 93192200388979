// src/store/redux/middleware/errorHandlingMiddleware.ts

import { MiddlewareAPI, isRejected } from '@reduxjs/toolkit'
import { setError } from '../slices/errorSlice'
import i18n from 'i18next'

export const errorHandlingMiddleware = (api: MiddlewareAPI) => (next: any) => (action: any) => {
  if (isRejected(action)) {
    let displayError: boolean = true
    let errorTitle: string = 'errors.unableToLoad'
    let errorMessage: string = 'errors.checkLater'

    // If a network error is detected, change the error message
    if (action.meta?.aborted && action.error?.message === 'No network connection') {
      errorTitle = 'errors.connectionProblemTitle'
      errorMessage = 'errors.connectionProblemDesc'
    } else {

      if (action.error?.message) {
        errorMessage = action.error.message
      }

      // Customize the message based on the action type if needed
      switch (action.type) {
        case 'auth/activateAccount/rejected':
        case 'auth/updateEmail/rejected':
        case 'auth/validateToken/rejected':
          displayError = false
          break
        case 'auth/initialise/rejected':
          if (action.payload === 'Not authenticated') {
            displayError = false
          }
          errorMessage = 'profilePage.profileError'
          break
        case 'application/fetchApplications/rejected':
        case 'application/fetchProjectApplications/rejected':
          errorTitle = 'errors.unableToContact'
          errorMessage = 'volunteerPage.contactError'
          break
        case 'application/updateApplication/rejected':
        case 'application/updateApplicationAction/rejected':
          errorMessage = 'projectLeader.updateErr'
          break
        case 'news/fetchNews/rejected':
          errorMessage = 'homePage.newsErrorMessage'
          break
        case 'profile/fetchProfile/rejected':
        case 'profile/fetchProfileOptions/rejected':
          errorMessage = 'profilePage.profileError'
          break
        case 'profile/updateProfile/rejected':
          errorMessage = 'profilePage.profileUpdateError'
          break
        case 'profile/deleteProfile/rejected':
          errorTitle = 'errors.unableToDelete'
          errorMessage = 'profilePage.profileDeletionError'
          break
        case 'projects/applyToProject/rejected':
          errorTitle = 'errors.unableToContact'
          errorMessage = 'volunteerPage.contactError'
          break
        case 'projects/fetchProjects/rejected':
          errorMessage = 'volunteerPage.projectsErrorMessage'
          break
        case 'projects/fetchProject/rejected':
          errorMessage = 'volunteerPage.projectErrorMessage'
          break
        case 'projects/fetchProjectsPL/rejected':
          errorMessage = 'more.clProjectsErr'
          break
        case 'projects/fetchShiftsPL/rejected':
          errorMessage = 'more.clProjectShiftsErr'
          break
        case 'projects/fetchProjectOptions/rejected':
          errorMessage = 'homePage.optionsErrorMessage'
          break
        case 'region/fetchLanguages/rejected':
          errorMessage = 'login.loadLanguagesErrorMessage'
          break
        case 'shifts/fetchShifts/rejected':
          errorMessage = 'homePage.shiftsErrorMessage'
          break
        case 'shifts/fetchShiftsProjectLeaders/rejected':
          errorMessage = 'shift.unableToLoadPLs'
          break
        case 'shifts/updateVolunteerShiftHourStatus/rejected':
          errorMessage = 'more.updateShiftPresenceErr'
          break
        case 'shifts/updateVolunteerShiftAnnouncement/rejected':
          errorMessage = 'more.updateAnnouncementErr'
          break
      }
      if (!displayError) {
        return next(action)
      }

      // Custom error messages based on status codes or error content
      if (action.payload) {
        switch (action.type) {
          case 'auth/login/rejected':
            errorTitle = 'login.thereWasAnError'
            errorMessage = 'login.otherIssueError'
            switch (action.payload.response.status) {
              case 401:
                errorMessage = 'login.accountNotActivated'
                break
              case 404:
                errorMessage = 'login.userNotFound'
                break
            }
            break

          case 'auth/signUp/rejected':
            errorTitle = 'login.thereWasAnError'
            errorMessage = 'login.otherIssueError'
            switch (action.payload.response.status) {
              case 401:
                errorMessage = 'login.signupAlreadyActiveErr'
                break
            }
            break

          case 'auth/resetPassword/rejected':
            errorTitle = 'login.thereWasAnError'
            errorMessage = 'login.otherIssueError'
            switch (action.payload.response.status) {
              case 404:
                errorMessage = 'login.resetWrongEmailErr'
                break
            }
            break

          case 'auth/updatePassword/rejected':
            errorTitle = 'updatePassword.thereWasAnError'
            errorMessage = 'updatePassword.error'
            switch (action.payload.response.status) {
              case 400:
                errorMessage = 'updatePassword.malformed'
                break
              case 404:
                errorMessage = 'updatePassword.notFound'
                break
            }
            break

          case 'profile/updateProfile/rejected':
            errorTitle = 'errors.unableToUpdate'
            switch (action.payload.response.status) {
              case 401:
                errorMessage = 'profilePage.profileEmailError'
                break
            }
            break

          case 'projects/fetchShiftsPL/rejected':
            if (action.payload.response.status === 404) {
              displayError = false
            }
            break

          case 'shifts/registerToShift/rejected':
          case 'shifts/unregisterFromShift/rejected':
            errorTitle = action.type == 'shifts/registerToShift/rejected' ?
              'shift.registrationErrorTitle' :
              'shift.cancellationErrorTitle'
            errorMessage = 'shift.registrationErrorDescDefault'
            switch (action.payload.response.status) {
              case 401:
              case 403:
              case 404:
              case 406:
              case 409:
                errorMessage = `shift.registrationErrorDesc${action.payload.response.status}`
                break
            }
            break

          case 'shifts/fetchShiftsProjectLeaders/rejected':
            if (action.payload.response.status === 404) {
              displayError = false
            }
            break
        }
      }
    }

    // Dispatch the setError action
    if (displayError) {
      api.dispatch(setError({
        title: i18n.t(errorTitle),
        message: i18n.t(errorMessage),
        actionType: action.type,
      }))
    }
  }

  return next(action)
}
