// src/api/BaseAPI.ts

import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import getBearerToken from '../utils/getBearerToken'
import { loadConfig } from '../utils/loadConfig'

let config: any = {}
loadConfig().then(cfg => {
  config = cfg
})

/**
 * BaseAPI class
 * @classdesc Class for interacting with the backend API
 */
export class BaseAPI {
  protected api: AxiosInstance

  /**
   * Constructor for the BaseAPI class
   * @param {string} regionApiCode - The region code for the API
   */
  constructor(regionApiCode: string) {
    const apiBaseURL: string | undefined = config.API_BASE_URL
    if (!apiBaseURL) {
      throw new Error('API_BASE_URL is not defined')
    }
    this.api = axios.create({
      baseURL: `${apiBaseURL}/${regionApiCode}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.setupInterceptors()
  }

  private setupInterceptors(): void {
    // Request interceptor for adding authentication token
    this.api.interceptors.request.use(
      async (config: InternalAxiosRequestConfig) => {
        // Add the token if it exists
        if (!config.headers.Authorization) {
          const token: string = await getBearerToken()
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
          }
        }
        return config
      },
      error => {
        return Promise.reject(error)
      },
    )

    // Response interceptor for handling errors
    this.api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error) => {
        if (error.response) {
          console.error('Response error:', error.response)
          // Handle specific status codes here (e.g., 401, 403)
          if (error.response.status === 401) {
            // Handle unauthorized access, if necessary
          }
        }
        // Ensure the error is propagated to middleware
        return Promise.reject(error)
      },
    )
  }
}
