import { ErrorMessage } from '@hookform/error-message'
import {
  IonLabel,
  IonItem,
  IonInput,
  IonButton,
  IonContent,
  IonLoading,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRippleEffect, IonAlert,
} from '@ionic/react'
import React, { useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import AuthTermsAndConditions from './AuthTermsAndConditions'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { useSelector } from 'react-redux'
import { AuthState, signUp } from '../../store/redux/slices/authSlice'
import { AppState } from '../../store/redux/types'
import { useNativeKeyboard } from '../../hooks/useNativeKeyboard'

type SignUpFormProps = {
  onClose: () => void;
};

const SignUpForm: React.FC<SignUpFormProps> = ({ onClose }) => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector((state: AppState): AuthState => state.auth)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const { t } = useTranslation()
  const handleNativeKeyboard = useNativeKeyboard()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm()
  const password = useRef<string | null | undefined>('')
  password.current = watch('password', '')

  const submitHandler = async (data: any) => {
    const { firstname, lastname, email, password } = data
    const resultAction = await dispatch(signUp({ firstname, lastname, email, password }))
    if (signUp.fulfilled.match(resultAction)) {
      setIsSubmitted(true)
      setShowAlert(true)
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle id="modalTitle" size="large">{t('login.signup')}</IonTitle>
          <IonButton slot="end" fill="clear" onClick={() => onClose()}>
            {t('btn.close')}
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonLoading isOpen={isLoading} message={t('loading.sending')} spinner="crescent" translucent={true} />
        <h3 className="ion-text-center">{t('login.registerInfoSessionTitle')}</h3>
        <form onSubmit={handleSubmit(submitHandler)}>
          <IonItem className="ion-no-padding">
            <Controller
              control={control}
              name="firstname"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <IonInput
                  label={t('forms.firstName')}
                  labelPlacement="stacked"
                  autofocus={true}
                  onIonChange={onChange}
                  onIonFocus={handleNativeKeyboard}
                  autocapitalize="on"
                  placeholder="Jane"
                  enterkeyhint="next"
                  required={true}
                  inputMode="text"
                />
              )}
              rules={{
                required: `${t('forms.required')}`,
                pattern: {
                  // eslint-disable-next-line no-misleading-character-class
                  value: /^[\p{L} -j́]*$/gu,
                  message: t('forms.invalidFirstName'),
                },
              }}
            />
          </IonItem>
          <ErrorMessage
            errors={errors}
            name="firstname"
            as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
          />
          <IonItem className="ion-no-padding">
            <Controller
              control={control}
              name="lastname"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <IonInput
                  label={t('forms.lastName')}
                  labelPlacement="stacked"
                  onIonChange={onChange}
                  onIonFocus={handleNativeKeyboard}
                  autocapitalize="on"
                  placeholder="Doe"
                  enterkeyhint="next"
                  required={true}
                  inputMode="text"
                />
              )}
              rules={{
                required: `${t('forms.required')}`,
                pattern: {
                  // eslint-disable-next-line no-misleading-character-class
                  value: /^[\p{L} -j́]*$/gu,
                  message: t('forms.invalidLastName'),
                },
              }}
            />
          </IonItem>
          <ErrorMessage
            errors={errors}
            name="lastname"
            as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
          />
          <IonItem className="ion-no-padding">
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <IonInput
                  type="email"
                  label={t('forms.emailAddress')}
                  labelPlacement="stacked"
                  onIonChange={onChange}
                  onIonFocus={handleNativeKeyboard}
                  placeholder="example@mail.com"
                  enterkeyhint="next"
                  autocapitalize="off"
                  inputMode="email"
                  required={true}
                />
              )}
              rules={{
                required: `${t('forms.required')}`,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                  message: t('forms.invalidEmail'),
                },
              }}
            />
          </IonItem>
          <ErrorMessage
            errors={errors}
            name="email"
            as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
          />
          <IonItem className="ion-no-padding">
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <IonInput
                  type="password"
                  label={t('forms.password')}
                  labelPlacement="stacked"
                  onIonChange={(e) => {
                    onChange(e.detail.value)
                    password.current = e.detail.value
                  }}
                  onIonFocus={handleNativeKeyboard}
                  placeholder={t('forms.enterPassword')}
                  enterkeyhint="next"
                  required={true}
                  inputMode="text"
                />
              )}
              rules={{
                required: `${t('forms.required')}`,
                pattern: {
                  value: /^(?=.*[a-z])(?=.*\d)[a-zA-Z\w\W]{8,}$/,
                  message: t('forms.invalidPassword'),
                },
              }}
            />
          </IonItem>
          <ErrorMessage
            errors={errors}
            name="password"
            as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
          />
          <IonItem className="ion-no-padding">
            <Controller
              control={control}
              name="confirmPassword"
              defaultValue=""
              render={({ field: { onChange } }) => (
                <IonInput
                  type="password"
                  label={t('forms.confirmPassword')}
                  labelPlacement="stacked"
                  onIonChange={onChange}
                  onIonFocus={handleNativeKeyboard}
                  placeholder={t('forms.confirmPassword')}
                  enterkeyhint="send"
                  required={true}
                  inputMode="text"
                />
              )}
              rules={{
                required: `${t('forms.required')}`,
                validate: (value): boolean => {
                  return value === watch('password')
                },
              }}
            />
          </IonItem>
          <ErrorMessage
            errors={errors}
            message={t('login.unmatchingPasswords')}
            name="confirmPassword"
            as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
          />
          <p className="ion-text-center ion-padding-vertical">{t('login.registerInfoSessionSubtitle')}</p>
          {isSubmitted ? (
            <IonButton className="ion-margin-top" color="success" disabled expand="block" type="submit">
              {t('login.codeSent')}
            </IonButton>
          ) : (
            <IonButton className="ion-margin-top ion-activatable ripple-parent" expand="block" type="submit">
              {t('btn.signUp')}
              <IonRippleEffect />
            </IonButton>
          )}
        </form>
        <AuthTermsAndConditions />
      </IonContent>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false)
          onClose()
        }}
        header={t('login.signupSuccess')}
        message={t('login.codeSent')}
        buttons={['OK']}
      />
    </>
  )
}

export default SignUpForm
