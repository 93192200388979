import React, { Suspense } from 'react'
import { ThemeProvider } from './themeContext'
import { Provider } from 'react-redux'
import { store } from './redux/store'

type AppProvidersProps = {
  children: React.ReactNode;
};

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  return (
    <Provider store={store}>
      <Suspense>
        <ThemeProvider>
          {children}
        </ThemeProvider>
      </Suspense>
    </Provider>
  )
}

export default AppProviders
